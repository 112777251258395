import { writable, type Writable } from 'svelte/store';

const defaultCurrentSlide: number = 0;

export const currentSlide: Writable<number> = writable(defaultCurrentSlide);

export const isChatLoading: Writable<boolean> = writable(true);

export const canPlaySound: Writable<boolean> = writable(false);
export const isDesktop: Writable<boolean> = writable(false);
export const isMobile: Writable<boolean> = writable(false);
export const isTouch: Writable<boolean> = writable(false);
export const isDebugMode: Writable<boolean> = writable(false);
