import successIcon from '@images/refactor/flasher/check.svg';
import infoIcon from '@images/refactor/flasher/info.svg';
import errorIcon from '@images/refactor/flasher/error.svg';
import warningIcon from '@images/refactor/flasher/warning.svg';
import { canPlaySound } from '@svelte/lib/store/common.ts';
import { get } from 'svelte/store';

export async function displayNotification(message, attribute = 'info', sound) {
  let type = attribute;
  let text = '';

  if (typeof message === 'object') {
    type = message.type;
    text = message.text;
  } else {
    text = message;
  }

  const styleData = getDataForNotificationType(type);

  if (get(canPlaySound) && sound) {
    try {
      sound.play();
    } catch (e) {
      console.log('Notification sound play', e);
    }
  }

  import('toastify-js/src/toastify.css');

  import('toastify-js')
    .then(({ default: Toastify }) => {
      Toastify({
        text,
        duration: 4000,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        className: 'eb24-toastify',
        escapeMarkup: false,
        avatar: styleData.avatar,
        style: {
          background: styleData.background
        }
      }).showToast();
    })
    .catch((error) => {
      console.error('An error occurred while loading the "toastify-js"', error);
    });
}

export function displaySuccessNotification(message, sound) {
  displayNotification(message, 'success', sound);
}

export function displayErrorNotification(message, sound) {
  displayNotification(message, 'error', sound);
}

export function displayInfoNotification(message, sound) {
  displayNotification(message, 'info', sound);
}

export function displayWarningNotification(message, sound) {
  displayNotification(message, 'warning', sound);
}

function getDataForNotificationType(type) {
  switch (type) {
    case 'info':
      return {
        background: '#2c67d5',
        avatar: infoIcon
      };
    case 'success':
      return {
        background: '#57af45',
        avatar: successIcon
      };
    case 'error':
      return {
        background: '#d31827',
        avatar: errorIcon
      };
    case 'warning':
      return {
        background: '#d3a718',
        avatar: warningIcon
      };
    default:
      return {
        background: '#2c67d5',
        avatar: infoIcon
      };
  }
}
