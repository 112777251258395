import eb24NotificationSound from '@assets/static/sounds/Eloboost_24_notification.mp3';
import {
  displayNotification,
  displaySuccessNotification,
  displayErrorNotification,
  displayInfoNotification,
  displayWarningNotification
} from '@js/modules/notifications.js';
import { isTouch, isMobile, isDesktop } from '@svelte/lib/store/common.ts';
import { get } from 'svelte/store';

export function setupGlobals() {
  if (import.meta.env.SSR) {
    return;
  }

  const body = document.body;

  const isTouchDevice =
    'ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)
      ? true
      : false;
  isTouch.set(isTouchDevice);

  const isMobileDevice = (function detect_mobile_browser() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      body.classList.add('mobile');
      return true;
    } else {
      body.classList.add('desktop');
      return false;
    }
  })();
  isMobile.set(isMobileDevice);
  isDesktop.set(!isMobileDevice);

  if (isTouchDevice) {
    body.classList.add('touch');
  } else {
    body.classList.add('notouch');
  }
}

export function setupActionTouch(list) {
  if (import.meta.env.SSR) {
    return;
  }

  if (!get(isTouch)) {
    return;
  }

  if (list === undefined) {
    list = document.querySelectorAll('.acn-touch, [data-acn="touch"]');
  }

  list.forEach((el) => {
    el.addEventListener('touchstart', () => {
      el.classList.add('touch');
    });
    el.addEventListener('touchend', () => {
      el.classList.remove('touch');
    });
  });
}

export async function setupWebsocketFlashListener(sound = eb24NotificationSound) {
  if (typeof USER_DATA !== 'undefined') {
    import('@js/helpers/get_eb24_pusher.js')
      .then(({ get_eb24_pusher }) => {
        get_eb24_pusher()
          .private('App.User.' + USER_DATA.id)
          .notification((notification) => {
            displayNotification(notification.message, undefined, new Audio(sound));
          });
      })
      .catch((error) => {
        console.error('An error occurred while loading the get_eb24_pusher', error);
      });
  }
}

export function addToastNotificationApiToWindow() {
  window.displayNotification = displayNotification;
  window.displaySuccessNotification = displaySuccessNotification;
  window.displayErrorNotification = displayErrorNotification;
  window.displayInfoNotification = displayInfoNotification;
  window.displayWarningNotification = displayWarningNotification;
}
